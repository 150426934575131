
import A3ConversationReport from "@/views/report/a3_conversation.vue";
import KpiDashboard from "@/views/report/KpiDashboard.vue";
import KpiOrganisation from "@/views/report/KpiOrganisation.vue";
import OrganisationAreaReport from "@/views/report/OrganisationArea.vue";
import ActionProgress from "@/views/report/ActionProgress.vue";
import ActionTable from "@/views/report/ActionTable.vue";
import ResultAreaReport from "@/views/report/ResultArea.vue";
import ReportOverview from "@/views/report/Overview.vue";

import A3Budget from "@/views/budget/A3Budget.vue";
import MyTasks from "@/views/user/MyTasks.vue";
import AllTasks from "@/views/AllTasks.vue";

import ReviewComplete from "@/views/review/ReviewComplete.vue";
import ReviewReport from "@/views/review/ReviewReport.vue";

export default [
  { path: "/report/:a3document_id", component: ReportOverview, props: true},
  { path: "/report/a3/:a3document_id", component: A3ConversationReport, props: true},
  { path: "/report/org_area/:a3document_id", component: OrganisationAreaReport, props: true},
  { path: "/report/action_progress/:a3document_id", component: ActionProgress, props: true},
  { path: "/report/action_table/:a3document_id", component: ActionTable, props: true},
  { path: "/report/result_area/:a3document_id", component: ResultAreaReport, props: true },
  { path: "/report/kpi_dashboard/:a3document_id", component: KpiDashboard, props: true },
  { path: "/report/kpi_org/:a3doc_id/:kpi_id", component: KpiOrganisation, props: true },

  { path: "/budget/a3/:a3document_id", component: A3Budget, props: true },
  { path: "/tasks", component: MyTasks },
  { path: "/action-dashboard", component: AllTasks },
  { path: "/review/a3/:a3document_id/:review_id", component: ReviewReport, props: true},
  { path: "/review/a3/complete/:a3document_id/:review_id", component: ReviewComplete, props: true},
];
