<template>
  <WithMenuContainer>
  <div style="float: right">
    <div v-if="license != null" >
        {{ document_count }} / {{ license.max_plans }}
        {{ $tc('ui.general.plan', license.max_plans) }} <br>
    </div>

    <div class="manage-link"
         v-if="$hasFeature('A3Management')"
        ><router-link to="/a3/manage">
        <i class="fas fa-cog"></i>
        {{ $t('ui.nav.a3_manage') }}
        </router-link>
    </div>

  </div>

    <div style="margin-bottom: 20px">
      <h1 style="margin-bottom: 0">{{ $t('ui.nav.my_plans') }} </h1>
    </div>

    <div class="tab-container">
      <div v-for="(timeframe, index) in timeframes"
        :key="timeframe.id"
        class="year-tab"
        :class="{'selected': selected_timeframe_index == index }"
        >
        <div @click="$router.push(`a3?timeframe=${index}`)">
            <i class="fas fa-star"
               v-if="timeframe.pin_on_top"
                ></i>
            <InfoBox v-if="timeframe.closed" tkey="timeframe.closed_inline">
                <template v-slot:display>
                    <i class="fas fa-lock" v-if="timeframe.closed"
                                           style="opacity:.4"
                        ></i>
                </template>
            </InfoBox>
            {{ timeframe.name }}

        </div>

        <div v-if="$hasFeature('OrgEditTimeframe') && selected_timeframe_index == index"
             class="add-timeframe-button"
             style="font-weight: normal"
             @click.prevent="$router.push(`/timeframe/edit/${timeframe.id}`)"
          >
          <i class="fas fa-edit"></i> {{ $t('ui.general.edit') }}
        </div>
      </div>

      <div v-if="$hasFeature('OrgEditTimeframe')"
           class="add-timeframe-button"
           @click="$router.push('timeframe/create')"
        >
        <i class="fas fa-plus"></i> {{ $t('ui.general.create') }}
      </div>
    </div>


    <div class="plan-container">
      <h2 v-if="timeframe">{{ $t("ui.index.plans_for") }}
        {{ timeframe.name }}
        <InfoBox v-if="timeframe.closed" tkey="timeframe.closed_inline">
            <template v-slot:display>
                <i class="fas fa-lock" v-if="timeframe.closed"
                                       style="opacity:.4"
                    ></i>
            </template>
        </InfoBox>
      </h2>

      <A3IndexButton
        :name="organisation.name"
        :selected="true"
        :a3="getRootA3()"
        :a3_rights="a3_rights"
        @new_a3="triggerNew"
      />

      <A3ProgramPlanList
         :a3_list="a3_list"
         :a3_rights="a3_rights"
         :parent_department_id="null"
         @new_a3="triggerNew"
      />

      <A3SubLevelContainer
        :departments="departments"
        :a3_list="a3_list"
        :a3_rights="a3_rights"
        @new_a3="triggerNew"
      />
    </div>


    <ErrorOverlay
           v-if="show_license_full_error"
           @close="show_license_full_error = false"
     >
      <h3>{{ $t('errors.a3.limit_reached_title') }} </h3>

      <div v-html="$t('errors.a3.limit_reached_message', [ license.max_plans ] )"></div>

    </ErrorOverlay>
  </WithMenuContainer>
</template>


<script>
import A3IndexButton from '@/components/organisation/A3IndexButton.vue';

import A3ProgramPlanList from '@/components/organisation/A3ProgramPlanList.vue';

export default {
  components: {
    A3IndexButton,
    A3ProgramPlanList,
  },

  data: () => ({
    departments: [],
    selected_d_id: null,
    a3_list: [],
    a3_rights: {},
    license: null,
    document_count: null,

    show_license_full_error: false,

    timeframes: [],
  }),

  computed: {
    selected_timeframe_index() {
      if (Object.keys(this.$route.query).length == 0) {
        return 0;
      }

      return this.$route.query['timeframe'];
    },
    timeframe() {
      if (this.timeframes == null || this.timeframes.length == 0) return null;

      return this.timeframes[this.selected_timeframe_index];
    },

    children() {
      if (this.selected_d_id == null) return [];
      return this.departments
        .filter(x => x.id == this.selected_d_id)[0]
        .children;
    },

    organisation() {
      return this.$store.getters['auth/organisation'];
    },
  },

  watch: {
    selected_timeframe_index() {
      this.loadA3List();
    },
    organisation() {
      this.loadOrganisation();
      this.loadA3Rights();
      this.loadTimeframes()
        .then(() => this.loadA3List());
      this.loadLicenseInfo();
    }
  },

  mounted() {
    this.loadOrganisation();
    this.loadA3Rights();
    this.loadTimeframes()
      .then(() => this.loadA3List());
    this.loadLicenseInfo();
  },

  filters: {
    lower(val) {
      return val.toLowerCase();
    },
  },

  methods: {

    triggerNew(data) {
      if (this.document_count >= this.license.max_plans) {
        this.show_license_full_error = true;
        return;
      }
      let options = [];
      options.push(`timeframe_id=${this.timeframe.id}`);
      if (data != null && data.department_id) {
        options.push(`department_id=${data.department_id}`);
      }
      if (data != null && data.program_plan) {
        options.push(`program_plan=1`);
      }

      this.$router.push(`/a3/create?${options.join("&")}`);
    },

    loadTimeframes() {
      return this.$http.get('timeframe')
        .then(res => {
          this.timeframes = res.body;
        });
    },

    loadOrganisation() {
      this.$http.get(
        `organisation/department`
      ).then(res => this.departments = res.body);
    },

    loadA3List() {
      this.$http.get(`a3/org?timeframe=${this.timeframes[this.selected_timeframe_index].id}`)
        .then(res => this.a3_list = res.body);
    },

    loadA3Rights() {
        this.$http.get('a3_rights')
            .then(res => {
                this.a3_rights = res.body;
            });
    },

    loadLicenseInfo() {
      this.$http.get(`organisation/license`)
        .then(res => {
          this.license = res.body.license;
          this.document_count = res.body.document_count;
        })
    },

    getRootA3() {
      for (let a3 of this.a3_list) {
        if (a3.department_id == null && a3.matrix_name == null) {
          return a3;
        }
      }
      return null;
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

.tab-container {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  border-right: 1px rgba($a3-green, .5) solid;
  padding-top: 15px;
  height: 100%;
  min-height: 600px;

  .year-tab {
    border: 1px rgba($a3-green, .5) solid;
    border-right: none;
    padding: 10px;
    color: $a3-green;
    margin-bottom: 5px;
    border-radius: 5px 0px 0px 5px;
    margin-right: -1px;
    cursor: pointer;
    transition: opacity .2s ease-out;
    opacity: .6;

    &.selected {
      opacity: 1;
      background-color: white;
      border-width: 2px;
      font-weight: bold;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.plan-container {
  display: inline-block;
  vertical-align: top;
  padding-left: 30px;
  width: 70%;
}

.yearplan-button {
  display: inline-block;
  padding: 20px 30px;
  min-width: 100px;
  max-width: 250px;
  vertical-align: middle;
  min-height: calc(2.6em);
  line-height: 1.3em;
  text-align: left;
  background-color: $a3-green;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 2px $a3-green solid;
  margin-right: 15px;

  &.program-plan {
    background-color: $program-plan-blue;
    border-color: $program-plan-blue;
    min-height: calc(1.3em);

    &.selected {
      background-color: $program-plan-blue;
      color: white;
    }
    &:hover {
      background-color: white;
      color: $program-plan-blue;
    }
  }

  transition: color .1s ease-in-out, background-color .1s ease-in-out, opacity .1s ease-out;

  > i {
    text-align: center;
    vertical-align: middle;
    float: left;
    margin-right: 8px;
    font-size: 1.3em;
  }

  &:hover {
    background-color: white;
    color: $a3-green;
    opacity: 1 !important;
  }

  &.selected {
    background-color: white;
    color: $a3-green;
    opacity: 1 !important;
  }

  &.grayed-out {
    opacity: .4;
  }
}

.plan-button-container {
  display: inline-block;
  position: relative;
  margin-top: 5px;

  .over-container {
    opacity: 0;
    position: absolute;
    height: 100;
    background-color: darken($a3-green, 10%);
    bottom: 0;
    border-radius: 5px;
    color: white;
    left: 0;
    right: 16px;
    padding: 10px;

    text-align: center;
    transform: scaleY(0);
    transform-origin: bottom center;
    transition: opacity .15s ease-out, transform .15s ease-out;
  }

  & .selected {
    .over-container {
      opacity: 1;
      transform: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.action-buttons {
  position: absolute;;
  top: 10%;
  left: 100%;
  width: 150px;
  z-index: 100;
  background-color: white;
  height: 80%;
  border-radius: 0px 5px 5px 0px;
  vertical-align: top;
  margin-right: 30px;

  div.button {
    color: $a3-green;
    cursor: pointer;

    &:hover {
      color: lighten($a3-green, 20%);
    }
  }
}

.sublevel-container {
  margin-top: 10px;
  margin-left: 60px;

  .arrow-container {
    margin: 0px 0px;
    padding: 0px 20px 10px 0px;
    display: inline-block;
    vertical-align: middle;
  }
  .sublevel-arrow-icon {
    opacity: .4;
    transform: rotate(90deg);
    color: $a3-green;
  }
}

.plan-action {
  position: absolute;
  right: 50px;
  top: 50px;
  width: 15vw;
}

.add-timeframe-button {
  padding-left: 5px;
  margin-top: 10px;
  color: #aaa;
  cursor: pointer;
  transition: color .2s ease-out, padding-left .2s ease-out;

  &:hover {
    color: $a3-green;
    padding-left: 10px;
  }

}

.manage-link {
  margin-top: 0.3em;
  padding: 5px;
  padding-top: 0px;

  a {
    text-decoration: none;
    color: $a3-green;
    opacity: .5;
    display: inline-block;
    transition: opacity .15s ease-out, transform .15s ease-out;

    &:hover {
      opacity: 1;
      transform: translateX(5px);
    }
  }
}
</style>
