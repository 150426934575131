<template>
  <div>

    <template v-if="timeframes">
        <div style="width: 100%; clear: both;">
            <div style="width: 350px;" class="form-row">
                <label>{{ $t('ui.timeframe.timeframe') }} :</label>
              <multiselect
                        id="timeframe-multiselect"
                        v-model="selected_timeframe"
                       :options="timeframes"
                       placeholder="Selecteer periode"
                       :selectLabel="$t('ui.general.enter_select')"
                       :selectedLabel="$t('ui.general.selected')"
                       :deselectLabel="$t('ui.general.enter_deselect')"
                       :multiple="false"
                       :close-on-select="true"
                       track-by="id"
                       label="name" />
            </div>
        </div>
    </template>

    <div id="my-tasks-table"></div>

  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import Tabulator from 'tabulator-tables';

export default {

  components: {
      Multiselect,
  },

  data: () => ({
    actions: [],
    timeframes: [],
    selected_timeframe: null,
  }),

  mounted() {
    this.loadTimeframes()
      .then(() => this.loadData());
  },

  computed: {
      organisation() {
          return this.$store.getters['auth/organisation'];
      }
  },

  watch: {
      selected_timeframe() {
          this.loadData();
      }
  },

  methods: {
    loadData() {
      if (this.selected_timeframe == null) return;

      let url = `report/my_tasks/${this.selected_timeframe.id}`;
      this.$http.get(url).then((res) => {
          this.actions = res.body;

          let generate_name = (department, program_name) => {
              let base_name = department !== null ? department.name : this.organisation.name;
              if (!!program_name) {
                  base_name += ` (P: ${program_name})`;
              }
              return base_name;
          };

          let table_data = res.body
            .map(x => {
                let a3doc_title = generate_name(x.department, x.a3_doc.program_name);
                return {
                    "name": x.action.name,
                    "target_date": x.action.target_date ? this.$d(new Date(x.action.target_date), "long") : '',
                    "from": a3doc_title,
                    "a3doc_id": x.a3_doc.id,
                    "action_id": x.action.id,
                    "state": x.action_status !== null ? `${x.action_status.percent_done}%` : null,
                    "_children": x.tasks.map(
                        task => ({
                            "name": task.name,
                            "state": task.done ? "Gereed" : "Te doen",
                            "target_date": task.target_date ? this.$d(new Date(task.target_date), "long") : '',
                            "from": a3doc_title,
                            "a3doc_id": x.a3_doc.id,
                            "action_id": x.action.id,
                            "task_id": task.id,
                        })
                    )
                }
            });

          let table = new Tabulator ('#my-tasks-table', {
              data: table_data,
              layout: "fitDataStretch",
              dataTree: true,
              dataTreeStartExpanded: true,
              layoutColumnsOnNewData:true,
              rowClick: (ev, row) => {
                  let url = `/a3/${row._row.data.a3doc_id}/action/${row._row.data.action_id}`;
                  if (row._row.data.task_id) {
                      url += `/task/${row._row.data.task_id}`;
                  }
                  this.$router.push(url);
              },
              columns: [
                  {
                      title: this.$t('ui.general.name'),
                      field: "name",
                  },
                  { title: this.$t('ui.action.state'), field: "state" },
                  { title: this.$t('ui.action.target_date'), field: "target_date" },
                  { title: this.$t('ui.report.from_plan'), field: "from" },
              ],
          });
      });
    },

    loadTimeframes() {
      return this.$http.get('timeframe').then((res) => {
        this.timeframes = res.body;
          if (this.timeframes.length > 0) {
              this.selected_timeframe = this.timeframes[0];
          }
      });
    },
  },

}
</script>
