<template>
    <div class="element-form" v-if="user_type != null">
      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.general.name') }}</label>
          <input v-model="user_type.name" class="a3-input" data-cy="input-name" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.general.description') }}</label>
          <input v-model="user_type.description" class="a3-input" data-cy="input-description" />
        </div>
      </div>

      <div class="form-row">
        <div class="form-field">
          <label>{{ $t('ui.user_type.general_rights') }}</label>
        </div>
        <FeatureEditor
             v-model="user_type.features"
             :input_mode="'array'"
             :show_groups="['A3Admin', 'Organisation', 'OrganisationAdmin']"
        />
      </div>

      <div class="form-row">
          <div class="form-field"
               style="display: inline-block;"
            >
              <label>{{ $t('ui.user_type.a3_rights') }}</label>
          </div>


          <template v-for="(rule, index) in user_type.a3_rules" >
              <div class="a3-rule-form-edit"
               v-if="rule.deleted == undefined || rule.deleted === false"
                  >
                  <div>
                      <select
                          class="a3-input"
                          v-model="user_type.a3_rules[index].a3_document_selection">
                          <option :value="'all'">
                            {{ $t('ui.user_type.selection.all') }}
                          </option>
                          <option :value="'within_department'">
                            {{ $t('ui.user_type.selection.within_department') }}
                          </option>
                          <option :value="'organisation'">
                            {{ $t('ui.user_type.selection.organisation') }}
                          </option>
                      </select>

                      <div class="add-timeframe-button"
                              @click="deleteRuleIndex(index)"
                          >
                          <i class="fas fa-times"></i>
                          {{ $t('ui.general.delete') }}
                      </div>
                  </div>

                  <div>
                      <FeatureEditor
                           v-model="user_type.a3_rules[index].features"
                           :input_mode="'array'"
                           :show_groups="['A3']"
                           :subfeatures="{
                                          'A3KpiUpdate': 'A3Edit',
                                          'A3ActionUpdate': 'A3Edit'
                                          }"
                      />
                  </div>
              </div>

          </template>

           <div style="margin-top: 30px; margin-bottom: 30px;">
               <div
                    class="add-timeframe-button"
                   @click="addRule" >
                   <i class="fas fa-plus"></i> {{ $t('ui.user_type.add_rule') }}
               </div>
            </div>
      </div>

      <div class="form-buttons">
          <button class="a3-button action" @click="$emit('submit')">
            <i class="fas fa-save"></i> {{ $t('ui.general.save') }}
          </button>

          <button class="a3-button cancel" @click="$emit('cancel')">
            {{ $t('ui.general.cancel') }}
          </button>
      </div>
    </div>
</template>

<script>

import FeatureEditor from '@/views/admin/forms/FeatureEditor.vue';

export default {
  components: {
    FeatureEditor,
  },

  data: () => ({
    ruleCount: 0,
  }),

  props: {
    user_type: {
      type: Object,
      required: true,
    }
  },

  mounted() {
      if (this.user_type.a3_rules == undefined) {
          this.$set(this.user_type, 'a3_rules', [])
      }
    this.updateRuleCount();
  },

  methods: {
    updateRuleCount() {
        this.ruleCount = this.user_type.a3_rules
          .filter((x) => !x.deleted)
          .length;
    },

    addRule() {
        this.user_type.a3_rules.push({
            "a3_document_selection": "all",
            "features": []
        });
        this.updateRuleCount();
        this.$forceUpdate();
    },

    deleteRuleIndex(index) {
      this.user_type.a3_rules[index].deleted = true;
      this.updateRuleCount();
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss">

.feature-edit-form-box {
    padding-left: calc(20% + 50px);
    box-sizing: border-box;
}

.a3-rule-form-edit {
    border-bottom: 1px rgba($a3-green, .3) solid;

    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 30px;

    > div {
        vertical-align: top;
        &:first-child {
            padding-top: 30px;

            > select {
                margin-bottom: 30px;
            }
        }
    }

}

.a3-rule-warning {
  opacity: .7;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
  border: 2px $general-orange solid;
  border-radius: 10px;
  background-color: rgba($general-orange, .1);
  display: inline-block;
}
</style>
